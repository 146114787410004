import React from 'react';
import MonthlyContribution from "../../MonthlyContribution/MonthlyContribution";
import {
    setTfsaContributeYou,
} from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const TfsaContributeYou = ( props ) => {
    const onChange = val => {
        props.setTfsaContributeYou(val)
    }

    return (
        < MonthlyContribution
            total={props.tfsaContribute}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        tfsaContribute: state.savingsAndInvestments.you.tfsa.contributeMonthly
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTfsaContributeYou : val => dispatch(setTfsaContributeYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TfsaContributeYou);