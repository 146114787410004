import React from 'react';
import { connect } from "react-redux";
import MonthlyContribution from "../../MonthlyContribution/MonthlyContribution";
import {
    setTfsaContributeSpouse,
} from "../../../../../store/actions/savingsAndInvestments";

const TfsaContributeSpouse = ( props ) => {
    const onChange = val => {
        props.setTfsaContributeSpouse(val)
    };
    return (
        < MonthlyContribution
            total={props.tfsaContribute}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        tfsaContribute: state.savingsAndInvestments.spouse.tfsa.contributeMonthly
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTfsaContributeSpouse : val => dispatch(setTfsaContributeSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TfsaContributeSpouse);